// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  hmr: false,
  pusher: {
    key: 'bf4b537612129f39a089',
    cluster: 'ap2',
    channel_prefix: ''
    // key: '5be594fb3074d926cc1f',
    // cluster: 'ap2',
  },
  // API_URL: 'http://awi_backend.local/',
  PROMO_STANDARDS_URL: 'https://app-api.optamarkgraphics.com/promostandards/',
  API_URL: 'https://app-api.optamarkgraphics.com/',
  // PROFILE_IMAGE_URL: 'http://awi_backend.local/profile_images/',
  PROFILE_IMAGE_URL: 'https://app-api.optamarkgraphics.com/profile_images/',
  AWS_FILE_PATH: 'https://awi-esb.s3.us-east-2.amazonaws.com/',
  AWS_PROFILE_PATH: 'https://awi-esb.s3.us-east-2.amazonaws.com/user-profile/',
  // API_PRODUCT_API: 'https://app-productsapi.optamarkgraphics.com/api/',
  API_PRODUCT_API: 'https://awi-productsapi.anythingwithink.com/api/',
  SITE_URL:'https://app.optamarkgraphics.com/',
  // STATIC_IMAGE: 'http://awi_backend.local/images/logo.png',
  STATIC_IMAGE: 'https://app-api.optamarkgraphics.com/images/logo.png',
  AWS_SUPPLIER_PATH: 'https://awi-esb.s3.us-east-2.amazonaws.com/supplier-image/',
  ARTWORK_SIZE:50, // Size in Mb
  //  UPLOAD_FILE(isDefault = false) {
  //     const currentPath = window.location.pathname;
  //     const urlArr = currentPath.split('/');
  //     if (localStorage.getItem('franchise_ids') && !isDefault && !isNaN(+urlArr[1])) {
  //        return 'http://awi-backend.local/' + urlArr[1] + '/images/';
  //     } else {
  //        return 'http://awi-backend.local/images/logo.png';
  //     }
  //  }
};

